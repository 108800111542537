import EmailAccountVerification from "container/emailAccountVerification/emailAccountVerification";
import LandingPage from "container/landingPage/landingPage";
import NotFound from "container/notFound/notFound";
import TermsAndConditions from "container/termsAndConditions/termsAndConditions";
import ChangeForgotPassword from "../container/changeForgotPassword/changeForgotPassword";
import NewsPage from "../container/newsPage/newsPage";
import { PATHS } from "./paths";
import Kuponi from "container/kuponi/kuponi";

const PathsAndElements = [
    {
        path: "*",
        element: <NotFound />,
    },
    {
        path: "/",
        element: <LandingPage />,
    },
    {
        path: PATHS.Global.EmailVerification + "/:verificationToken",
        element: <EmailAccountVerification />,
    },
    {
        path: PATHS.Global.ForgotPassword + "/:tokenId",
        element: <ChangeForgotPassword />,
    },
    {
        path: PATHS.Global.TermsAndConditions,
        element: <TermsAndConditions />,
    },
    {
        path: PATHS.Global.News,
        element: <NewsPage />,
    },
    {
        path: PATHS.Global.Kuponi,
        element: <Kuponi />,
    }
];

export default PathsAndElements;

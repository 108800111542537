import { StringResources as Res, translate as t } from "utils/language/languageResource";
import SwcCircle from "components/swcCircle/swcCircle";
import { Formik } from "formik";
import { useAppDispatch } from "redux/store";
import { activateCouponWithCodeInitialValues } from "utils/formikValidation/formikInitialValues";
import { onSubmitActivateCouponWithCode } from "utils/formikValidation/formikOnSubmit";
import { activateCouponWithCodeValidationSchema } from "utils/formikValidation/validationSchema";
import SwcButton from "components/swcButton/swcButton";
import "./kuponi.scss";
import SwcInputControlFormik from "components/swcInputControlFormik/swcInputControlFormik";
import { activateCouponWithCodeInputData } from "utils/formikValidation/formInputData";

const Kuponi = () => {
    const dispatch = useAppDispatch();

    return (
        <Formik
            initialValues={activateCouponWithCodeInitialValues}
            validationSchema={activateCouponWithCodeValidationSchema}
            onSubmit={values => {
                onSubmitActivateCouponWithCode(values, dispatch);
            }}
        >
            {({ handleSubmit }) => (
                <div className="activate-coupon-with-code__container">
                    <SwcCircle />
                    <div className="activate-coupon-with-code__form">
                        <div className="activate-coupon-with-code__title-text">
                            {t(Res.ActivateCoupon)}
                        </div>
                        {activateCouponWithCodeInputData.data.map((val, index) => {
                            return (
                                <SwcInputControlFormik
                                    key={index}
                                    name={val.name}
                                    inputType={val.inputType}
                                    htmlFor={val.htmlFor}
                                    labelTitle={val.labelTitle}
                                    styleDefault="activate-coupon-with-code__input--default"
                                    styleError="activate-coupon-with-code__input--error"
                                    styleLabel="activate-coupon-with-code__label"
                                    errorInsideInput={false}
                                />
                            );
                        })}
                        <SwcButton
                            text={t(Res.Submit)}
                            onClickHandler={handleSubmit}
                            style="activate-coupon-with-code__button"
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default Kuponi;
import { activateCouponWithCodeThunk, changeForgottenPasswordThunk } from "redux/actions/authActions";
import { executeContainerThunkDispatch } from "utils/helperFunctions";
import { PAGE_ROUTES } from "utils/paths";
import { IActivateCouponWithCodeFormik, ICustomerChangeForgotPasswordFormik } from "./validationModelInterface";

export const onSubmitCustomerChangeForgotPassword = async <T extends ICustomerChangeForgotPasswordFormik>(
    values: T,
    forgotPasswordToken: string,
    dispatch: any,
    navigate: any
) => {
    const { customerPassword } = values;

    let result = await executeContainerThunkDispatch(dispatch, changeForgottenPasswordThunk, {
        newPassword: customerPassword,
        forgotPasswordToken: forgotPasswordToken,
    });

    if (result.isValid) {
        navigate(PAGE_ROUTES.Global);
    }
};

export const onSubmitActivateCouponWithCode = async <T extends IActivateCouponWithCodeFormik>(
    values: T,
    dispatch: any
) => {
    const { couponCode } = values;

    await executeContainerThunkDispatch(dispatch, activateCouponWithCodeThunk, {
        code: couponCode,
    });
};

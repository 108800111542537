import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { nameof } from "ts-simple-nameof";
import { IActivateCouponWithCodeFormik, ICustomerChangeForgotPasswordFormik, InputDataProps } from "./validationModelInterface";

export const customerChangeForgotPasswordInputData: InputDataProps = {
    data: [
        {
            labelTitle: t(Res.NewPassword),
            name: nameof<ICustomerChangeForgotPasswordFormik>(x => x.customerPassword),
            inputType: "password",
        },
        {
            labelTitle: t(Res.ConfirmPassword),
            name: nameof<ICustomerChangeForgotPasswordFormik>(x => x.confirmCustomerPassword),
            inputType: "password",
        },
    ],
};

export const activateCouponWithCodeInputData: InputDataProps = {
    data: [
        {
            labelTitle: t(Res.Code),
            name: nameof<IActivateCouponWithCodeFormik>(x => x.couponCode),
            inputType: "text",
        }
    ]
}
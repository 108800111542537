export const PAGE_ROUTES = {
    Global: "/",
};

export const PATHS = {
    Global: {
        Home: "/",
        Login: "/login",
        ForgotPassword: "/forgot-password",
        EmailVerification: "/email-verification",
        TermsAndConditions: "/terms-and-conditions",
        News: "/news",
        Kuponi: "/kuponi",
    },
};

export const CONTROLLER_ROUTES = {
    Account: "/api/account/",
    Coupon: "/api/customer/coupon/"
};

export const ENDPOINTS = {
    Account: {
        Register: CONTROLLER_ROUTES.Account + "register",
        ForgotPassword: CONTROLLER_ROUTES.Account + "forgot-password",
        ChangeForgottenPassword: CONTROLLER_ROUTES.Account + "change-forgotten-password",
        Login: CONTROLLER_ROUTES.Account + "login",
        RefreshToken: CONTROLLER_ROUTES.Account + "refresh-token",
        EmailVerification: CONTROLLER_ROUTES.Account + "email-verification",
    },
    Coupon: {
        UsePurchasedCouponWithCode: CONTROLLER_ROUTES.Coupon + "use-purchased-coupon-with-code"
    }
};

import { EmailVerificationRequest, UsePurchasedCouponWithCodeRequest, UserChangeForgottenPasswordRequest } from "redux/models/authModels";
import { executePostRequest } from "./services";
import { StringResources as Res, translate as t } from "utils/language/languageResource";
import { ENDPOINTS } from "utils/paths";

export async function AuthEmailVerification(request: EmailVerificationRequest) {
    return await executePostRequest(
        ENDPOINTS.Account.EmailVerification + `/${request.verificationToken}`,
        {},
        {
            successMessage: t(Res.AccountVerified),
            useBackendErrorMessage: true,
            errorMessage: t(Res.DefaultErrorMessage),
        }
    );
}

export async function AuthServiceChangeForgottenPassword(request: UserChangeForgottenPasswordRequest) {
    return await executePostRequest(
        ENDPOINTS.Account.ChangeForgottenPassword,
        {
            newPassword: request.newPassword,
            forgotPasswordToken: request.forgotPasswordToken,
        },
        {
            successMessage: t(Res.SuccessChangeForgottenPassword),
            useBackendErrorMessage: true,
            errorMessage: t(Res.DefaultErrorMessage),
        }
    );
}

export async function AuthServiceActivateCouponWithCode(request: UsePurchasedCouponWithCodeRequest) {
    return await executePostRequest(
        ENDPOINTS.Coupon.UsePurchasedCouponWithCode,
        {
            code: request.code,
        },
        {
            successMessage: t(Res.SuccessActivateCouponWithCode),
            useBackendErrorMessage: true,
            errorMessage: t(Res.DefaultErrorMessage),
        }
    );
}

import { t } from "i18next";

export enum StringResources {
    FirstName = "FirstName",
    LastName = "LastName",
    Ssn = "Ssn",
    Password = "Password",
    Email = "Email",
    Submit = "Submit",
    PleaseEnterAValid = "PleaseEnterAValid",
    Register = "Register",
    Login = "Login",
    SucessNewAccount = "SuccessNewAccount",
    DefaultErrorMessage = "DefaultErrorMessage",
    ForgotPassword = "ForgotPassword",
    ForgotPasswordEmailLink = "ForgotPasswordEmailLink",
    NewPassword = "NewPassword",
    LoginSuccess = "LoginSuccess",
    Customer = "Customer",
    Company = "Company",
    Logout = "Logout",
    PageNotFound = "PageNotFound",
    GhostStoleThisPage = "GhostStoleThisPage",
    CompanyName = "CompanyName",
    ViewLoyaltyPrograms = "ViewLoyaltyPrograms",
    Join = "Join",
    NoLoyaltyProgramsAvailable = "NoLoyaltyProgramsAvailable",
    Available = "Available",
    Joined = "Joined",
    Search = "Search",
    CreateLoyaltyProgram = "CreateLoyaltyProgram",
    LoyaltyProgramName = "LoyaltyProgramName",
    CreateLoyaltyProgramSuccess = "CreateLoyaltyProgramSuccess",
    SuccessCreateLoyaltyAccount = "SuccessCreateLoyaltyAccount",
    AccountVerified = "AccountVerified",
    NoLoyaltyProgramsJoined = "NoLoyaltyProgramsJoined",
    Details = "Details",
    Close = "Close",
    LoyaltyAccountNumber = "LoyaltyAccountNumber",
    Points = "Points",
    Confirm = "Confirm",
    MyProfile = "MyProfile",
    RegisterCompany = "RegisterCompany",
    RegisterCustomer = "RegisterCustomer",
    ConfirmPassword = "ConfirmPassword",
    CompanyNameRequired = "CompanyNameRequired",
    InvalidEmail = "InvalidEmail",
    EmailRequired = "EmailRequired",
    InvalidSsn = "InvalidSsn",
    SsnRequired = "SsnRequired",
    MinPasswordLength = "MinPasswordLength",
    PasswordRequired = "PasswordRequired",
    PasswordMustMatch = "PasswordMustMatch",
    ConfirmPasswordRequired = "ConfirmPasswordRequired",
    FirstNameRequired = "FirstNameRequired",
    LastNameRequired = "LastNameRequired",
    Update = "Update",
    AccountUpdatedSuccess = "AccountUpdatedSuccess",
    UpdateCustomerDetails = "UpdateCustomerDetails",
    CompanyLoyaltyPrograms = "CompanyLoyaltyPrograms",
    NoLoyaltyProgramsCreated = "NoLoyaltyProgramsCreated",
    CreateNewApiKey = "CreateNewApiKey",
    ApiKeys = "ApiKeys",
    Deactivate = "Deactivate",
    SuccessDeactivateApiKey = "SuccessDeactivateApiKey",
    SuccessGenerateApiKey = "SuccessGenerateApiKey",
    LoyaltyProgramNameRequired = "LoyaltyProgramNameRequired",
    Customers = "Customers",
    InsertAccountNumbers = "InsertAccountNumbers",
    CustomerAccountNumber = "CustomerAccountNumber",
    LastActiveDate = "LastActiveDate",
    RecyclingYard = "RecyclingYard",
    EditCustomer = "EditCustomer",
    AddRemovePoints = "AddRemovePoints",
    DisableEnablePointsCollection = "DisableEnablePointsCollection",
    NoAccountNumbersFound = "NoAccountNumbersFound",
    PointsRule = "PointsRule",
    EditPoints = "EditPoints",
    Products = "Products",
    AmountInGrams = "AmountInGrams",
    NoProductsFound = "NoProductsFound",
    SuccessUpdateProductRule = "SuccessUpdateProductRule",
    AdministrationRecyclingYards = "AdministrationRecyclingYards",
    Address = "Address",
    City = "City",
    WorkingHoursMondayFriday = "WorkingHoursMondayFriday",
    WorkingHoursSaturday = "WorkingHoursSaturday",
    WorkingHoursSundayHolidays = "WorkingHoursSundayHolidays",
    Telephone = "Telephone",
    NoTransactionsFound = "NoTransactionsFound",
    SuccessCreateNewLocation = "SuccessCreateNewLocation",
    Transactions = "Transactions",
    PleaseSelectProduct = "PleaseSelectProduct",
    Date = "Date",
    LocationCount = "LocationCount",
    ProductCount = "ProductCount",
    CustomerCount = "CustomerCount",
    TransactionCount = "TransactionCount",
    AmountInKilograms = "AmountInKilograms",
    LocationAddress = "LocationAddress",
    ProductType = "ProductType",
    TransactionId = "TransactionId",
    TransactionTime = "TransactionTime",
    CreateProduct = "CreateProduct",
    ProductName = "ProductName",
    ProductNameRequired = "ProductNameRequired",
    MinProductAmount = "MinProductAmount",
    ProductAmountRequired = "ProductAmountRequired",
    MinPointAmount = "MinPointAmount",
    PointAmountRequired = "PointAmountRequired",
    ProductAmount = "ProductAmount",
    PointAmount = "PointAmount",
    SuccessCreateNewProduct = "SuccessCreateNewProduct",
    AddUser = "AddUser",
    CreateCompany = "CreateCompany",
    VerificationTime = "VerificationTime",
    ContactPersonRequired = "ContactPersonRequired",
    ContactPersonEmailRequired = "ContactPersonEmailRequired",
    PhoneNumberRequired = "PhoneNumberRequired",
    AddressRequired = "AddressRequired",
    ContactPerson = "ContactPerson",
    ContactPersonEmail = "ContactPersonEmail",
    SuccessCreateNewCompany = "SuccessCreateNewCompany",
    PleaseSelectLoyaltyProgramGroup = "PleaseSelectLoyaltyProgramGroup",
    CreateCompanyUser = "CreateCompanyUser",
    SuccessCreateCompanyUser = "SuccessCreateCompanyUser",
    Coupons = "Coupons",
    CouponStarts = "CouponStarts",
    CouponEnds = "CouponEnds",
    CouponUsage = "CouponUsage",
    Value = "Value",
    Used = "Used",
    CouponName = "CouponName",
    NoCouponsFound = "NoCouponsFound",
    CreateCoupon = "CreateCoupon",
    MinCouponAmount = "MinCouponAmount",
    CouponAmountRequired = "CouponAmountRequired",
    CouponNameRequired = "CouponNameRequired",
    MinCouponPoints = "MinCouponPoints",
    CouponPointsRequired = "CouponPointsRequired",
    CouponStartRequired = "CouponStartRequired",
    MinCouponNumberOfUses = "MinCouponNumberOfUses",
    CouponNumberOfUsesRequired = "CouponNumberOfUsesRequired",
    CouponEndRequired = "CouponEndRequired",
    CouponAmount = "CouponAmount",
    CouponPoints = "CouponPoints",
    CouponNumberOfUses = "CouponNumberOfUses",
    SuccessCreateCoupon = "SuccessCreateCoupon",
    CouponInformationRequired = "CouponInformationRequired",
    CouponInformation = "CouponInformation",
    SuccessDeleteCoupon = "SuccessDeleteCoupon",
    Rava = "Rava",
    Home = "Home",
    WhatIsRava = "WhatIsRava",
    HowToJoin = "HowToJoin",
    RavaBenefits = "RavaBenefits",
    RavaSmartWasteSorting = "RavaSmartWasteSorting",
    LandingPageHomeDescription = "LandingPageHomeDescription",
    WhatIsItAbout = "WhatIsItAbout",
    WhatIsRava_1 = "WhatIsRava_1",
    WhatIsRava_2 = "WhatIsRava_2",
    WhyJoin = "WhyJoin",
    WhyJoinDescription = "WhyJoinDescription",
    ReduceWaste = "ReduceWaste",
    ReduceWasteDescription = "ReduceWasteDescription",
    ReduceCost = "ReduceCost",
    ReduceCostDescription = "ReduceCostDescription",
    MaximazeRecovery = "MaximazeRecovery",
    MaximazeRecoveryDescription = "MaximazeRecoveryDescription",
    ReduceCarbonFootprint = "ReduceCarbonFootprint",
    ReduceCarbonFootprintDescription = "ReduceCarbonFootprintDescription",
    WhatAreRavaBenefits = "WhatAreRavaBenefits",
    RavaBenefitsDescription_1 = "RavaBenefitsDescription_1",
    RavaBenefitsDescription_2 = "RavaBenefitsDescription_2",
    RavaBenefitsDescription_3 = "RavaBenefitsDescription_3",
    DownloadRavaApp = "DownloadRavaApp",
    GooglePlayStoreAndroid = "GooglePlayStoreAndroid",
    Or = "Or",
    AppleStoreIOS = "AppleStoreIOS",
    RavaBenefitsTitle_2 = "RavaBenefitsTitle_2",
    RavaBenefitsTitle_3 = "RavaBenefitsTitle_3",
    RavaBenefitsTitle_4 = "RavaBenefitsTitle_4",
    AllRightsReserved = "AllRightsReserved",
    SuccessChangeForgottenPassword = "SuccessChangeForgottenPassword",
    PleaseFillThisField = "PleaseFillThisField",
    ChangeForgottenPassword = "ChangeForgottenPassword",
    ContactEmail = "ContactEmail",
    ReadPrivacyPolicy = "ReadPrivacyPolicy",
    Here = "Here",
    ChangesToTerms = "ChangesToTerms",
    TermsAndConditionsChangesToTerms = "TermsAndConditionsChangesToTerms",
    AccountRegistration = "AccountRegistration",
    TermsAndConditionsAccountRegistration = "TermsAndConditionsAccountRegistration",
    AccountSecurity = "AccountSecurity",
    TermsAndConditionsAccountRegistrationAccountSecurity = "TermsAndConditionsAccountRegistrationAccountSecurity",
    DataAccuracy = "DataAccuracy",
    TermsAndConditionsAccountRegistrationDataAccuracy = "TermsAndConditionsAccountRegistrationDataAccuracy",
    TerminationOfAccount = "TerminationOfAccount",
    TermsAndConditionsAccountRegistrationTerminationOfAccount = "TermsAndConditionsAccountRegistrationTerminationOfAccount",
    AcceptanceOfTerms = "AcceptanceOfTerms",
    TermsAndConditionsAcceptanceOfTerms = "TermsAndConditionsAcceptanceOfTerms",
    TermsAndConditions = "TermsAndConditions",
    TermsAndConditionsTopDescription = "TermsAndConditionsTopDescription",
    News = "News",
    GoBack = "GoBack",
    Published = "Published",
    NewsTitle_1 = "NewsTitle_1",
    NewsDescription_1 = "NewsDescription_1",
    ForMoreNewsClick = "ForMoreNewsClick",
    NewsTitle_2 = "NewsTitle_2",
    NewsDescription_2 = "NewsDescription_2",
    ForMoreDetailsClick = "ForMoreDetailsClick",
    MustBeExactly7Characters = "MustBeExactly7Characters",
    SuccessActivateCouponWithCode = "SuccessActivateCouponWithCode",
    Code = "Code",
    ActivateCoupon = "ActivateCoupon",
}

export function translate(stringResources: StringResources) {
    return t(stringResources);
}

import * as yup from "yup";
import { StringResources as Res, translate as t } from "utils/language/languageResource";

export const customerChangeForgotPasswordValidationSchema = yup.object().shape({
    customerPassword: yup.string().min(8, t(Res.MinPasswordLength)).required(t(Res.PasswordRequired)),
    confirmCustomerPassword: yup
        .string()
        .min(8, t(Res.MinPasswordLength))
        .oneOf([yup.ref("customerPassword"), null], t(Res.PasswordMustMatch))
        .required(t(Res.ConfirmPasswordRequired)),
});

export const activateCouponWithCodeValidationSchema = yup.object().shape({
    couponCode: yup.string().min(7, t(Res.MustBeExactly7Characters)).max(7, t(Res.MustBeExactly7Characters)).required(),
});